import * as React from 'react';
//import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import Video from '@bgea/kaltura-player/dist/KalturaPlayer';
import facebookIcon from '../images/icon-facebook-black.svg';
import xIcon from '../images/icon-x-black.svg';
import Social from '../components/social';

export default function Blog({ location, pageContext, data }) {
  const blog = pageContext;
  const meta = {
    title: blog.node.frontmatter.title,
    date: blog.node.frontmatter.date,
  };
  const url = location.href ? location.href : '';

  return (
    <Layout meta={meta} data={data}>
      <div
        className={`blog-post title-container ${
          blog.node.frontmatter.featureImage ? 'has_feature_img' : ''
        }`}
      >
        <div className="titling-bkgd">
          <div className="titling">
            <p className="cat-tag">{blog.node.frontmatter.tag}</p>
            <h1>{blog.node.frontmatter.title}</h1>
            <p className="date">{blog.node.frontmatter.date}</p>
            <div className="social has-text-centered">
              {blog.node.frontmatter.mobileShare && (
                <a href={blog.node.frontmatter.mobileShare}>
                  <svg
                    id="mobileShare"
                    viewBox="0 0 400 400"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title id="mobileShare-title">mobile Share</title>
                    <g>
                      <circle class="bkgd-circle" cx="200" cy="200" r="190" />
                      <path
                        class="mobile-icon"
                        d="M249.3,238.9c-9.4,0-17.9,4.3-23.5,11L156,215.4c0.5-2.2,0.8-4.5,0.8-6.9c0-2.4-0.3-4.7-0.8-7l69.9-34.9
		c5.6,6.7,14,11,23.4,11c16.8,0,30.5-13.7,30.5-30.5s-13.7-30.5-30.5-30.5s-30.5,13.7-30.5,30.5c0,2.4,0.3,4.7,0.8,7l-69.9,34.9
		c-5.6-6.7-14-11-23.4-11c-16.8,0-30.5,13.7-30.5,30.5s13.7,30.5,30.5,30.5c9.4,0,17.9-4.3,23.5-11l69.8,34.6
		c-0.5,2.2-0.8,4.5-0.8,6.9c0,16.8,13.7,30.5,30.5,30.5s30.5-13.7,30.5-30.5S266.1,238.9,249.3,238.9z"
                      />
                    </g>
                  </svg>
                </a>
              )}
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebookIcon} alt="Share to Facebook" />
                <span className="screen-reader-text">
                  Opens in a new tab or window.
                </span>
              </a>

              <a
                href={`https://twitter.com/intent/tweet?url=${url}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={xIcon} alt="Post to X" />
                <span className="screen-reader-text">
                  Opens in a new tab or window.
                </span>
              </a>
            </div>
          </div>
        </div>
        {blog.node.frontmatter.featureImage && (
          <div className="feature-img">
            <img
              src={blog.node.frontmatter.featureImage}
              alt={
                blog.node.frontmatter.featureImageAltText
                  ? blog.node.frontmatter.featureImageAltText
                  : ''
              }
            />
          </div>
        )}
      </div>
      <div className="blog-post content container py-5">
        {blog.node.frontmatter.kaltura && (
          <div className="videoContainer">
            <section className="video">
              <div className="flexVideo">
                <Video id={blog.node.frontmatter.kaltura} />
              </div>
            </section>
          </div>
        )}
        {blog.node.body && <MDXRenderer>{blog.node.body}</MDXRenderer>}

        <div className="back-next">
          {blog.previous && (
            <div className="previous">
              <a href={blog.previous.frontmatter.slug} className="nav-backnext">
                <span className="mobile">Previous Post</span>
                <span className="large">{blog.previous.frontmatter.title}</span>
              </a>
            </div>
          )}
          {blog.next && (
            <div className="next">
              <a href={blog.next.frontmatter.slug} className="nav-backnext">
                <span className="mobile">Next Post</span>
                <span className="large">{blog.next.frontmatter.title}</span>
              </a>
            </div>
          )}
        </div>
      </div>
      <Social url={url} />
    </Layout>
  );
}
